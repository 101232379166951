.brandContainer {
  display: grid;
  align-items: center;
  column-gap: 0.1rem;
  max-width: 80vw;
  color: white;
  text-align:'center';
  font-weight: 'bold';
  margin-left: 0.5rem;
}


.headerContainer {
  padding: 0.4rem;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.navbar {
  color: black;
  padding: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
}
