.token-label {
    float: left;
    margin: 17px 5px;
    font-size: 15px;
    min-width: 15%;
}

.token-container {
    margin: 30px 5px;
}

.token-text-container {
    width: 55% !important;
    float: left;
    height: 50px;
}

.token-Skeleton {
    height: 50px !important;
}

.token-details {
    height: 50px !important;
    background-color: rgba(0, 0, 0, 0.11);
    text-align: center;
    padding: 18px;
}

.token-view {
    float: left;
    margin: 17px 5px 17px 7px;
    color: royalblue;
    font-size: 15px;
    cursor: pointer;
    min-width: 15%;
}
