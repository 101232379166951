#root {
  height: 100%;
  width: 100%;
}

p {
    margin: 0.2rem 0;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    color: red;
  }
}

* {
  box-sizing: border-box;
}