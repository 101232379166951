.outlined {
    box-shadow: none;
    text-transform: none !important;
    font-size: 1rem;
    padding: 6px 12px;
    width: 176px !important;
    border-radius: 24px !important;
    background-color: white !important;
    border: 1px solid #3B3331 !important;
    color: #3B3331 !important;

    &:hover {
        box-shadow: none;
        background-color: #141414 !important;
        border-color: #141414;
        color: white !important;
    }

    &:focus {
        box-shadow: none;
        background-color: #141414 !important;
        border-color: #141414;
        color: white !important;
    }
}

.contained {
    box-shadow: none;
    text-transform: none !important;
    font-size: 17px !important;
    padding: 6px 12px;
    width: 100% !important;
    border-radius: 24px !important;
    background-color: #D71E28 !important;
    border: 1px solid #D71E28 !important;
    color: #FFFFFF !important;
    height: 40px;
    font-weight: 600;
    line-height: 22px;

    &:hover {
        box-shadow: none;
        background-color: #B01C24 !important;
        border-color: #141414;
        color: white !important;
    }

    &:focus {
        box-shadow: none;
        background-color: #D71E28 !important;
        border-color: #141414;
        color: white !important;
    }
}

.secondary-btn {
    box-shadow: none;
    text-transform: none !important;
    font-size: 17px !important;
    padding: 6px 12px;
    width: 100% !important;
    border-radius: 24px !important;
    background-color: #FFFFFF !important;
    border: 1px solid gray !important;
    color: #3B3331 !important;
    height: 40px;
    font-weight: 600;
    line-height: 22px;

    &:hover {
        box-shadow: none;
        background-color: lightgray !important;
        border-color: gray;
        color: white !important;
        color: black !important;
    }

    &:focus {
        box-shadow: none;
        background-color: lightgray !important;
        border-color: gray;
        color: white !important;
        color: black !important;
    }
}

.disabled {
    box-shadow: none;
    text-transform: none !important;
    font-size: 1rem;
    padding: 6px 12px;
    width: 176px !important;
    border-radius: 24px !important;
    color: #B5ADAD !important;
}
