.brandContainer {
  display: flex;
  align-items: center;
  column-gap: 0.1rem;
  max-width: 80vw;
}

.sub-logo-text {
    font-size: 17px !important;
    color: #FFFFFF!important;
    width: 167px!important;
    font-family: SF Pro Text!important;
    padding-left: 10px;
    padding-right: 0px;
}

.logo-img {
    width: 148px;
    Height: 16px;
    padding-left:10px;
}

.headerContainer {
    padding: 0.4rem;
    background-color: #d71e28;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: rgb(255, 205, 65);
}

.navbar {
  color: black;
  padding: 0;
  height: 100%;

  display: flex;
  justify-content: space-between;
}

