.dialog-title {
    font-size: 26px;
    font-family: 'SF Pro Text';
    line-height: 28px;
}

.dialog-details {
    font-size: 20px;
    line-height: 28px;
    font-family: 'SF Pro Text';
    padding-top: 15px;
}
