.card-container .card-head {
    text-align: left;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
}

.card-container .card-detail {
    text-align: left;
    line-height:20px;
    margin:5px 0px;
}
