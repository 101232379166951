/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.grayColor {
    background-color: #f3f6f9 !important;
}

.textAlignCenter {
    text-align: center !important;
}

.captchaControls {
    border: 1px solid lightgrey !important;
    box-shadow: 0px 0px 0px 0px lightgrey !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}

/*overwirte*/

html {
    background-color: #F4F0ED;
}

.pb-3,
.py-3 {
    padding-bottom: 0px !important;
}

.container {
    width: 100% !important;
    padding-right: 0px;
    padding-left: 0px;
    max-width: 100%;
}

body {
    font-family: 'Wells Fargo Sans';
    font-size: 15px;
    background-color: #f5f5f5 !important;
    color: #141414;
}

.bg-panel {
    margin: 16px;
    min-height: 200px;
    justify-items: center;
    display: grid
}

.card-panel {
    box-shadow: 0px 0px 20px 0px #00000052, 0px 1px 4px 0px #00000040;
    border-radius: 10px;
    background-color: #FFFFFF;
    min-height: 100px;
    padding: 10px;
    width: calc(min(100%, 38rem));
}

.msg-icon {
    width: 28px;
    float: left;
    margin: 0px 20px 4px 20px;
}

.msg-text {
    margin-top: 4px;
}

.assistance-text {
    margin-top: 42px;
    padding: 4px 8px;
}

.assistance-number {
    color: #D71E28;
}

.input-group > .custom-txtbox {
    height: 54px;
    padding-top: 16px;
    width: 100%;
    left: 0px;
    border: none;
    border-bottom: solid 1px #787070;
    font-size: 18px;
    color: #787070;
}

.custom-txtbox:focus ~ label,
.custom-txtbox.valid ~ label,
.custom-txtbox.input-validation-error ~ label {
    color: #5A469B;
    top: 0px;
    outline: none !important;
    font-size: 12px;
    margin-bottom: 0px !important;
}

.custom-txtbox.input-validation-error ~ label {
    color: #D71E28;
    background-image: url('../src/assets/Images/error.svg');
    background-repeat: no-repeat;
    padding-left: 25px;
    background-size: 16px;
}

.custom-txtbox.input-validation-error {
    border-bottom: solid 1px #D71E28;
    background-color: #FCF7F6;
}

.custom-txtbox:focus {
    background-color: #F9F7F6;
    outline: none !important;
    border-bottom: solid 1px #5A469B;
}

.custom-txtbox:hover {
    background-color: #F9F7F6;
    outline: none !important;
}

#nucaptcha-answer:focus {
    background-color: #F9F7F6;
    outline: none !important;
    border-bottom: solid 1px #5A469B;
}

#nucaptcha-answer:hover {
    background-color: #F9F7F6;
    outline: none !important;
}

.input-group > label {
    position: relative;
    top: 18px;
    left: 0px;
    font-size: 20px;
    transition: .2s;
    pointer-events: none;
    color: #4B4444;
}


#my-nucaptcha-answer-wrapper > label {
    color: #5A469B;
    outline: none !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    text-align: left;
}

.name-initial {
    color: #D71E28;
    margin-bottom: 10px;
}

.follow-instruction {
    margin: 10px 10px;
}

.step {
    text-align: center;
    margin: 4px 0px 0px 0px;
    font-size: 16px;
}

    .step > span {
        background-color: #FFE6A0;
        border-radius: 20px;
        padding: 5px 12px;
        font-size: 16px;
    }

.step-instruction {
    margin: 12px 4px 20px 4px;
}

.display-group {
    margin: 36px 4px 10px 4px;
}

    .display-group > label {
        color: #787070;
        font-size: 14px;
    }

    .display-group > .code {
        background-color: #E8E5E5;
        min-height: 0px;
        padding: 14px 5px;
        text-align: center;
        font-size: 20px;
        color: #000000;
        border-radius: 5px;
        margin-top: 8px;
        font-weight: 600;
    }

.pwd-hint {
    color: #787070;
    font-size: 14px;
    font-style: italic;
    text-align: center;
}

.reset-instruction {
    font-size: 14px;
    margin: 48px;
    text-align: center;
}

.pwd-criteria {
    color: #000000;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.collapse-toggle-btn {
    color: #9433C1;
    font-size: 20px;
}

.collapse-toggle-icon {
    width: 20px;
    float: right;
}


.progress {
    height: 4px;
    margin: 0px 20px;
}

    .progress > .progress-success {
        background-color: #D71E28;
        height: 4px;
        float: left;
    }

    .progress > .progress-pending {
        background-color: #787070;
        margin-left: 2px;
        height: 4px;
        float: left;
    }

.progress-header {
    padding: 20px 20px 10px 20px;
    text-align: center;
    font-weight:400;
}

.progress-message {
    padding: 15px 20px 20px 20px;
    font-size: 14px;
    text-align: center;
}

.modal-content {
    border-radius: 10px;
    position: relative;
    background-color: white;
    align-items: center;
    max-width: 500px;
    margin: 1.75rem auto;
    top: 20%;
}

#ChallengeCode {
    padding-top: 35px;
    border-bottom: none;
    font-family: auto, sans-serif;
}

.media-container {
    text-align: center;
}

.shift-left {
    float: left;
    margin-bottom: 20px;
}

.shift-right {
    float: right;
    margin-bottom: 20px;
}

.strip-3 {
    width: 32.66%
}

.strip-lable-container {
    text-align: center;
}


.dialog-content {
    border-radius: 8px;
}

.hideDiv {
    display: none;
}

.btn-wrapper {
    width: 156px;
    margin: 5px;
}

.strip-container {
    justify-content: center;
    display: grid;
    padding: 10px;
}

.red-hr {
    border: 2px solid #D71E28!important;
}

.hr-custom {
    border: 2px solid #787070;
}


/*mobile (min-width: 375px)*/
@media only screen and (min-width: 0px) {
    /*button CSS start*/

    .button-primary {
        background-color: #D71E28;
        border: none;
        border-radius: 24px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 100%;
        max-width: 100%;
        touch-action: auto;
    }

        .button-primary:hover {
            background-color: #B01C24;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-primary:focus {
            background-color: #B01C24;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

    .button-secondary {
        border-color: #3B3331;
        border-width: 1px;
        background-color: #FFFFFF;
        border-radius: 24px;
        color: #3B3331;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        font-weight: normal;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 100%;
        max-width: 100%;
        touch-action: auto;
        margin-bottom: 10px;
    }

        .button-secondary:hover {
            background-color: #141414;
            color: #FFFFFF;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:focus {
            background-color: #141414;
            color: #FFFFFF;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:active {
            background-color: #3B3331;
        }

    /*button CSS End*/

    .instruction-text {
        margin: 16px 16px;
        font-size: 20px;
        text-align: left;
    }

    .btn-container {
        margin: 24px 16px;
        display: flex;
        flex-direction: column;
    }

    .btn-red {
        width: 100%;
        background-color: #D71E28;
    }

    .btn-trans {
        width: 100%;
        border: solid #213547 1px !important;
        color: #213547 !important;
    }

    .strips {
        width: 300px;
    }

    .strip {
        float: left;
        padding: 1px;
        width: 16.66%;
    }

    #media-container {
        float: left;
        width: 170px;
    }

    #nucaptcha-media {
        width: 170px !important;
    }

    #new-challenge-link {
        float: left;
        margin: 0px 0px;
    }

    #my-nucaptcha-answer-wrapper {
        float: left;
        height: 54px;
        width: 100%;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        text-align: left;
    }

    #nucaptcha-answer {
        float: left;
        height: 40px;
        width: 100%;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        outline: none !important;
    }

    #new-challenge {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/new_challange.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #player-mode {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/player_mode.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #nd-captcha {
        height: 144px;
    }

    .encryption-img {
        width: 210px;
        margin: 1.0rem;
    }

    .encryption-img-container {
        text-align: center;
    }
}

@media only screen and (min-width: 321px) {
    #media-container {
        float: left;
        width: 150px;
    }

    .img {
        width: 150px !important;
    }

    #nucaptcha-media {
        width: 150px !important;
    }
}

/*tab*/
@media only screen and (min-width: 569px) {

    .button-primary {
        background-color: #D71E28;
        border: none;
        border-radius: 24px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 100%;
        max-width: 100%;
        touch-action: auto;
    }

        .button-primary:hover {
            background-color: #B01C24;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-primary:focus {
            background-color: #B01C24;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

    .button-secondary {
        border-color: #3B3331;
        border-width: 1px;
        background-color: #FFFFFF;
        border-radius: 24px;
        color: #3B3331;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        font-weight: normal;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 100%;
        max-width: 100%;
        touch-action: auto;
        margin-bottom: 10px;
    }

        .button-secondary:hover {
            background-color: #141414;
            color: #FFFFFF;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:focus {
            background-color: #141414;
            color: #FFFFFF;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:active {
            background-color: #3B3331;
        }

    /*button CSS End*/

    .instruction-text {
        margin: 17px 17px;
        font-size: 20px;
        text-align: left;
    }

    .btn-container {
        margin: 24px 16px;
        display: flex;
        flex-direction: column;
    }

    .btn-red {
        width: 100%;
        background-color: #D71E28;
    }

    .btn-trans {
        width: 100%;
        border: solid #213547 1px !important;
        color: #213547 !important;
    }

    .captcha-img {
        width: 45%;
        float: left;
    }

    .captcha-container {
        width: 55% !important;
        float: left;
    }

    .strips {
        width: 600px;
    }

    .strip {
        float: left;
        padding: 1px;
    }

    #media-container {
        float: left;
    }

    #new-challenge-link {
        float: left;
        margin: 0px 0px;
    }

    #my-nucaptcha-answer-wrapper {
        width: 45% !important;
        margin-top: 6px;
        margin-left: 16px;
        float: left;
        height: 54px;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        text-align: left;
    }

    #nucaptcha-answer {
        float: left;
        height: 40px;
        width: 100%;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        outline: none !important;
    }

    #new-challenge {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/new_challange.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #player-mode {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/player_mode.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #nd-captcha {
        height: auto;
    }

    .encryption-img {
        width: 210px;
        margin: 1.0rem;
    }

    .encryption-img-container {
        text-align: center;
    }
}

/*laptop*/
@media only screen and (min-width: 768px) {

    /*button CSS*/
    .button-primary {
        background-color: #D71E28;
        border: none;
        border-radius: 24px;
        margin-left: 30px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 176px;
        max-width: 100%;
        touch-action: auto;
    }

        .button-primary:hover {
            background-color: #B01C24;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-primary:focus {
            background-color: #B01C24;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

    .button-secondary {
        border-color: #3B3331;
        border-width: 1px;
        margin-left: 30px;
        background-color: #FFFFFF;
        border-radius: 24px;
        color: #3B3331;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        font-weight: normal;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 176px;
        max-width: 100%;
        touch-action: auto;
        margin-bottom: 10px;
    }

        .button-secondary:hover {
            background-color: #141414;
            color: #FFFFFF;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:focus {
            background-color: #141414;
            color: #FFFFFF;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:active {
            background-color: #3B3331;
        }

    /* button css end*/

    .instruction-text {
        margin: 20px 20px;
        font-size: 20px;
        text-align: left;
    }

    .btn-container {
        margin: 24px 16px;
        display: flex;
        flex-direction: row;
    }

    .btn-red {
        width: 176px;
        background-color: #D71E28;
    }

    .btn-trans {
        width: 176px;
        border: solid #213547 1px !important;
        color: #213547 !important;
    }

    .captcha-img {
        width: 45%;
        float: left;
    }

    .captcha-container {
        width: 55% !important;
        float: left;
    }

    .strips {
        width: 600px;
    }

    .strip {
        float: left;
        padding: 1px;
    }

    #media-container {
        width: 40% !important;
        float: left;
    }

    #new-challenge-link {
        float: left;
        margin: 0px 0px;
    }

    #my-nucaptcha-answer-wrapper {
        width: 45% !important;
        margin-top: 6px;
        margin-left: 16px;
        float: left;
        height: 54px;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        text-align: left;
    }

    #nucaptcha-answer {
        float: left;
        height: 40px;
        width: 100%;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        outline: none !important;
    }

    #new-challenge {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/new_challange.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #player-mode {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/player_mode.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #nd-captcha {
        height: auto;
    }

    .encryption-img {
        width: 450px;
        margin: 1.0rem;
    }

    .encryption-img-container {
        text-align: center;
    }
}

@media only screen and (min-width: 1080px) {

    /*button CSS*/
    .button-primary {
        background-color: #D71E28;
        border: none;
        border-radius: 24px;
        margin-left: 30px;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 176px;
        max-width: 100%;
        touch-action: auto;
    }

        .button-primary:hover {
            background-color: #B01C24;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-primary:focus {
            background-color: #B01C24;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

    .button-secondary {
        border-color: #3B3331;
        border-width: 1px;
        margin-left: 30px;
        background-color: #FFFFFF;
        border-radius: 24px;
        color: #3B3331;
        cursor: pointer;
        display: inline-block;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 1rem;
        font-weight: normal;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        min-width: 176px;
        max-width: 100%;
        touch-action: auto;
        margin-bottom: 10px;
    }

        .button-secondary:hover {
            background-color: #141414;
            color: #FFFFFF;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:focus {
            background-color: #141414;
            color: #FFFFFF;
            font-weight: 600;
            text-decoration: underline;
            transition: background-color 0.6s ease;
        }

        .button-secondary:active {
            background-color: #3B3331;
        }

    /* button css end*/

    .showDiv {
        display: block;
    }

    .hideDiv {
        display: none;
    }

    .instruction-text {
        margin: 20px 20px;
        font-size: 20px;
        text-align: left;
    }

    .btn-container {
        margin: 24px 16px;
        display: flex;
        flex-direction: row;
    }

    .btn-red {
        width: 176px;
        background-color: #D71E28;
    }

    .btn-trans {
        width: 176px;
        border: solid #213547 1px !important;
        color: #213547 !important;
    }

    .captcha-img {
        width: 45%;
        float: left;
    }

    .captcha-container {
        width: 55% !important;
        float: left;
    }

    .strips {
        width: 600px;
    }

    .strip {
        float: left;
        padding: 1px;
    }

    #media-container {
        width: 40% !important;
        float: left;
    }

    #new-challenge-link {
        float: left;
        margin: 0px 0px;
    }

    #my-nucaptcha-answer-wrapper {
        width: 45% !important;
        margin-top: 6px;
        margin-left: 16px;
        float: left;
        height: 54px;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        text-align: left;
    }

    #nucaptcha-answer {
        float: left;
        height: 40px;
        width: 100%;
        left: 0px;
        border: none;
        border-bottom: solid 1px #787070;
        font-size: 18px;
        color: #787070;
        outline: none !important;
    }

    #new-challenge {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/new_challange.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #player-mode {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 18px;
        overflow: hidden;
        text-indent: -20000px;
        text-decoration: none;
        text-transform: none;
        background-image: url(../src/assets/Images/player_mode.svg);
        background-repeat: no-repeat;
        margin: 0px 0px 5px 0px;
        background-position: center;
        background-size: 16px;
        border: 2px solid #787070;
    }

    #nd-captcha {
        height: auto;
    }

    .encryption-img {
        width: 450px;
        margin: 1.0rem;
    }

    .encryption-img-container {
        text-align: center;
    }
}
