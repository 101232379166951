.logoff-menu-button {
    color: white !important;
}

.user-name {
    width: 250px;
    text-align: center;
    font-weight: bold !important;
    margin: 10px;
    border-bottom: 1px solid lightgray;
}

.user-details {
    margin: 15px 20px 5px 20px;
    font-size: 16px;
}

.loginid {
    float: right;
}

.emailId {
    margin-top: 15px;
}
