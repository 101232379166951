.dialog-text {
    text-align: left;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 25px;
}

.dialog-content {
    border-radius: 8px;
}
