:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1;
  font-weight: 400;

  color-scheme: light dark;
  color: #000000;
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}
.MuiBreadcrumbs-root  {
  color: #141414 !important;
}


body {
  margin: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #F4F0ED;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.MuiTouchRipple-root{
  position: inherit !important;
  pointer-events: inherit !important;
  color:transparent;
}

.MuiAvatar-img{
    object-fit: none !important;
}

.MuiButton-root{
  color:transparent;
}

/* .MuiCardContent-root:last-child {
  padding-bottom: 8px !important;
} */

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
