.rsa-option-container {
    width: 150px;
    margin: 20px;
    border-radius: 20px;
}

.rsa-option-menu {
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
}

.rsa-option-menu:hover {
    background-color: lightgray;
}

.rsa-option-button {
    border: 1px solid !important;
    background-color: #D71E28 !important;
    border-radius: 24px !important;
    height: 40px !important;
    margin-top: 0.5rem !important;
    width: min(100%, 10rem);
    color: #ffffff !important;
    border-color: #d71e28 !important;
}

.rsa-option-button:hover {
    background-color: #B01C24 !important;
    border-color: #B01C24 !important;
    box-shadow: none !important;
    color: white !important;
}

.rsa-option-button:focus, button:focus-visible {
    outline: none;
}
