$red: #d71e28;

.card {
    border: none;
    margin-bottom: 0.75rem;
    min-height: 8rem;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 0px 0px 20px 0px #00000052, 0px 1px 4px 0px #00000040 !important;
    border-radius: 10px;

    p {
        line-height: 120%;
    }

    &:hover {
        cursor: auto;
        transform: scale(1.01, 1.02);
        transform-origin: 0 0;
        box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
    }

    .card__content {
        padding: 1rem 0.75rem !important;
    }

    .card__imageContainer {
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .card__placeholder {
        background-color: lighten(#d0ad95, 12%);
        border-radius: 5px;
    }
}

.font-bold {
    font-weight: 600;
    margin-bottom: 0;
}

.font-light {
    color: #a4a4a4;
    font-size: 14px;
    margin-bottom: 0;
}

.oneLineText {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical
}

.oneline {
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card_name {
    font-size: 17px;
    text-align: left;
    font-weight: 600;
    line-height: 20px;
}

.card_content {
    text-align: left;
    margin: 5px;
}

.children-container {
    text-align: left;
}
