.heading {
    justify-content: center;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    min-height: 35rem;
    height: 100%;
    background: white;
    display: block;
    padding: 2rem;
    border-radius: 4px;

    /* media queries */
    @media (min-width: 767px) {
        // width: 35rem !important;

        p {
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            max-width: 100% !important;
        }
        img{
            width: 30%;
        }
    }

    @media (max-width: 767px) {
        width: 100% !important;

        p {
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
        img{
            width: 50%;
        }
    }
}