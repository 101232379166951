.account-container {
    width: 58rem;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0 #0000001A;
}

.account-container > .head-title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    width: 100%;
}

.account-container .sub-head-title {
    margin-bottom: 18px;
    text-align: left;
    font-size: 15px;
    line-height: 20px;
}

.account-container .email-input-text {
    border-radius: 24px !important;
    height: 40px;
    margin-top: 10px;
    width: auto;
}

.account-container .btn-container {
    width: 99px;
}

.account-container .email-lbl-text {
    font-weight: 600;
    line-height: 20px;
    font-size: 17px;
}

.account-container .email-txt-container {
    width: 237px;
    height: 70px;
}

.account-container .my-form {
    width: 100% !important;
}

.guidance {
    text-align: left;
    font-size: 15px;
    margin: 20px 5px;
    border: 1px, solid;
    padding: 20px;
}

.guidance .gui-head {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
}

.guidance .gui-content {
    margin: 20px 0px;
}

.guidance .gui-content .gui-content-detail {
    text-decoration: underline solid;
    font-size: 16px;
    line-height: 21px;
    text-decoration-skip-ink: none;
}

.guidance .gui-content li {
    margin: 8px 0px;
    line-height: 19px;
    font-weight: 700;
}
